<template>
  <div>
    <slot name="header" />
    <div v-if="!vippsUrl">
      <div
        v-if="!loaded"
        class="loader mx-auto my-12" />

      <div v-else-if="loaded && failed" />

      <div
        class="container mx-auto pt-12 pb-12 max-w-md relative"
        v-else>
        <div
          v-if="expressEnabled"
          class="mb-8 flex justify-center">
          <div
            v-if="!addons"
            class="loader" />
          <checkbox-list
            v-else
            :options="addons"
            v-model="selectedAddons"
            class="mb-4"
            label-class="font-bold"
            alignment="top">
            <template slot-scope="{ item }">
              <div>
                <div class="mb-1">
                  <span class="font-bold">{{ item.title }}</span> <span class="font-bold">+{{ item.price }} kr</span>
                </div>
                <span class="block text-sm text-muted">
                  {{ item.description }}
                </span>
              </div>
            </template>
          </checkbox-list>
        </div>

        <div class="mb-8 text-md">
          <div class="total-row font-bold">
            <div class="mr-4">
              {{ formTitle }}
            </div>

            <div class="text-right">
              {{ formatPrice(shopifyCart.subtotal) }}
            </div>
          </div>

          <div v-if="shopifyCart.coupon_applicable">
            <div class="total-row">
              <div class="mr-4">
                Rabatt ({{ shopifyCart.code }})
              </div>

              <div class="text-right">
                - {{ formatPrice(shopifyCart.discounted_amount) }}
              </div>
            </div>

            <div class="total-row font-bold">
              <div class="mr-4">
                Totalt
              </div>

              <div class="text-right">
                {{ formatPrice(shopifyCart.total) }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="updating"
          class="absolute inset-0 bg-brand-background bg-opacity-50 flex items-center justify-center z-10">
          <div class="loader" />
        </div>

        <div class="my-8">
          <div v-if="showCouponInput">
            <form
              @submit.prevent="fetchCart"
              class="flex flex-wrap w-full">
              <input-field
                v-model="coupon"
                placeholder="Rabattkode"
                class="flex-1 mr-4"
                ref="input" />

              <default-button
                type="submit"
                primary
                small
                :loading="updating">
                Bruk
              </default-button>
            </form>
          </div>
          <div
            v-else
            class="text-center">
            <button
              @click.prevent="showCouponInput = true"
              class="inline-block mx-auto underline text-center font-bold">
              Har du en rabattkode? Trykk her!
            </button>
          </div>
        </div>

        <div
          v-if="shopifyCart.total > 0"
          class="text-center mt-12 mb-4">
          <button
            @click="initiateVipps"
            style="height: 52px;"
            :class="vippsClass"
            :disabled="submitting">
            <img
              src="~@/assets/images/betal-med-vipps.svg"
              class="w-64"
              alt="Betal med Vipps">
          </button>
        </div>

        <div class="text-center mx-auto w-64">
          <default-button
            v-if="shopifyCart.total > 0"
            secondary
            class="w-full py-2"
            style="padding-left: 10px; padding-right: 10px;"
            @click.prevent.native="next">
            Betal med kort/faktura
          </default-button>

          <default-button
            v-else
            primary
            class="w-full py-2"
            style="padding-left: 10px; padding-right: 10px;"
            @click.prevent.native="next">
            Fullfør bestilling
          </default-button>
        </div>
      </div>

      <slot
        v-if="!vippsUrl"
        :is-valid="false"
        :is-hidden="true" />
    </div>
    <div v-else>
      <div
        v-if="showLazyVippsLink"
        class="container mx-auto pt-12 pb-12 max-w-lg relative text-center">
        <div class="sm:text-lg mt-4 text-center text-muted mb-8">
          Du blir nå videresendt til betaling hos Vipps. Om du ikke blir videresendt automatisk kan du bruke knappen nedenfor.
        </div>

        <a
          :href="vippsUrl"
          class="vipps-button py-4 px-12 text-white font-semibold inline-block hover:bg-opacity-75">Gå til Vipps betaling</a>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/utils/event-bus"
import axios from "axios"

export default {
  data () {
    return {
      submitting: false,
      loaded: false,
      shopifyCart: null,
      showCouponInput: false,
      coupon: null,
      updating: false,
      failed: false,
      addons: null,
      loadingAddons: false,
      vippsUrl: null,
      redirectUrl: null,
      showLazyVippsLink: false
    }
  },

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formTitle: {
      type: String,
      default: null
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    slideFormData: {
      type: Array,
      default () {
        return []
      }
    },

    prev: {
      type: Function,
      default () {
        return () => {}
      }
    },

    setErrorMessage: {
      type: Function,
      default () {
        return () => {}
      }
    },

    next: {
      type: Function,
      default () {
        return () => {}
      }
    },

    setFormData: {
      type: Function,
      default () {
        return () => {}
      }
    },

    expressEnabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    vippsClass () {
      return {
        "vipps-button": true,
        loading: this.submitting,
      }
    },

    selectedAddons: {
      get () {
        return this.formData.addons
      },

      set (value) {
        this.setFormData("addons", value)
      }
    },
  },

  created () {
    this.fetchAddons()
    this.fetchCart()
  },

  watch: {
    selectedAddons () {
      this.fetchCart()
    }
  },

  methods: {
    fetchAddons () {
      this.loadingAddons = true
      axios.get("/api/addons")
        .then(({ data }) => {
          this.loadingAddons = false
          this.addons = data
        })
    },

    fetchCart() {
      this.updating = true
      axios.post("/api/coupon", { formId: this.formData.formId, coupon: this.coupon, addons: this.selectedAddons })
        .then(({ data }) => {
          this.shopifyCart = data.data
          this.setFormData("coupon", data.data.code)
          this.loaded = true
          this.updating = false
        })
        .catch((e) => {
          this.setErrorMessage(e.response.data.message)
          this.loaded = true
          this.failed = true
          this.updating = false
        })
    },

    initiateVipps () {
      this.submitting = true
      this.setErrorMessage(null)
      EventBus.$emit("checkoutComplete", {
        paymentMethod: "vipps"
      })

      axios.post("/api/vipps/initiate", { fields: this.slideFormData, ...this.formData })
        .then(({ data }) => {
          this.vippsUrl = data.url
          this.orderId = data.order_id
          this.vippsStatusUrl = data.vipps_status_url
          setTimeout(() => {
            this.showLazyVippsLink = true
          }, 500)

          this.$nextTick(() => {
            window.location.replace(data.url)
          })
        })
        .catch(error => {
          this.submitting = false
          this.setErrorMessage(error.response.data.message)
        })
    },

    formatPrice (price) {
      var formatter = new Intl.NumberFormat("NB-no", {
        style: "currency",
        currency: "NOK"
      })

      return formatter.format(price)
    }
  }
}
</script>

<style lang="scss" scoped>
.vipps-button {
  background-color: #FF5B24;
  border-radius: 4px;

  &.loading img {
    opacity: 0;
  }
}

.total-row {
  @apply flex justify-between items-center py-2;
  border-bottom: 1px solid rgba(0, 0, 0, .05);

  &:last-child {
    border-bottom: none;
  }
}
</style>
